import { getColor } from '@/shared';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useBreakpoints } from '@/shared/providers/Device';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { TabGroupStoryblok, TabStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { Controller, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../Container/Container';
import styles from './TabGroup.module.scss';

const TabGroup = (props: TabGroupStoryblok) => {
    const { isXXSmall, isExtraSmall, isSmall } = useBreakpoints();
    const isMobile = isXXSmall || isExtraSmall || isSmall;
    const theme = useThemeSection();
    const isDark = theme === 'Dark';
    const hexColor = useContext(ColorContext);
    const pageColor = getColor(hexColor, isDark);
    const { tabGroupContent, paddingTop } = props.blok;
    const [selectedTab, setSelectedTab] = useState(tabGroupContent[0]);
    const [startDragX, setStartDragX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [mobileLefts, setMobileLefts] = useState([20]);
    const [selectorStyle, setSelectorStyle] = useState({
        width: '0',
        left: '0',
        borderColor: 'transparent',
        zIndex: 5,
        top: '1px',
    });
    const dragSpeed = 2;
    const tabContainerRef = useRef<HTMLDivElement | null>(null);

    const tabRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const [swiper, setSwiper] = useState<any | null>(null);

    useEffect(() => {
        if (tabRefs && tabRefs.current) {
            const tabRefsArray = Object.values(tabRefs.current);
            const numTabs = Object.keys(tabRefs.current).length;
            let arr = [20];
            for (let i = 0; i < numTabs; i++) {
                const tab = tabRefsArray[i];
                const tabRect = tab!.getBoundingClientRect();

                if (i === 0) {
                    arr.push(tabRect.width + 20);
                } else if (i === 1) {
                    const lastWidth = arr.pop();
                    const calcWidth = lastWidth! + tabRect.width;
                    arr.push(lastWidth ?? 0);
                    arr.push(calcWidth);
                } else {
                    const lastWidth = arr.pop();
                    const calcWidth = lastWidth! + tabRect.width;
                    arr.push(lastWidth ?? 0);
                    arr.push(calcWidth);
                }
            }
            setMobileLefts(arr);
            const index = Object.keys(tabRefs.current).findIndex(key => key === selectedTab._uid);
            const left = index === 0 ? (isMobile ? `${20}px` : `${0}px`) : `${arr[index]}px`;

            setSelectorStyle({
                width: `${tabRefs.current[selectedTab._uid]!.clientWidth}px`,
                left: left,
                borderColor: pageColor ? pageColor : 'transparent',
                zIndex: 5,
                top: '1px',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabRefs, pageColor, isMobile, props.blok.tabGroupContent[0].tabName]);

    const centerTab = (uid: string) => {
        const container = tabContainerRef?.current as HTMLDivElement;
        const tab = tabRefs?.current[uid] as HTMLDivElement;
        const containerRect = container.getBoundingClientRect();
        const tabRect = tab.getBoundingClientRect();
        const index = Object.keys(tabRefs.current).findIndex(key => key === uid);
        setSelectorStyle({
            width: `${tab.clientWidth}px`,
            left: isMobile ? `${mobileLefts[index]}px` : `${tabRect.x - containerRect.x}px`,
            borderColor: pageColor,
            zIndex: 5,
            top: '1px',
        });

        const containerMid = containerRect.left + containerRect.width / 2;
        const tabMid = tabRect.left + tabRect.width / 2;
        const diff = tabMid - containerMid;

        const startScrollLeft = container.scrollLeft;

        const duration = 1000;
        let startTime: number;

        const animateScroll = (currentTime: number) => {
            if (!startTime) startTime = currentTime;

            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);

            // Interpolate between the start and end positions based on progress
            const currentScroll = startScrollLeft + progress * diff;
            container.scrollLeft = currentScroll;

            if (progress < 1) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    };

    return (
        <Container
            blok={props.blok}
            code="fullpage"
            className={{
                root: clsx({ [styles.dark]: isDark }),
            }}
            style={{ root: { paddingTop: paddingTop ? paddingTop + 'px' : 0 } }}
        >
            <Container code="standard" className={{ wrap: styles.wrapper }}>
                <div
                    ref={tabContainerRef}
                    onMouseDown={e => {
                        setStartDragX(e.pageX);
                    }}
                    onMouseLeave={() => {
                        setStartDragX(0);
                    }}
                    onMouseUp={() => {
                        setStartDragX(0);
                    }}
                    onMouseMove={e => {
                        if (startDragX !== 0) {
                            const x = e.pageX;
                            const scrollDiff = (startDragX - x) * dragSpeed;
                            const scrollElement = e.currentTarget;
                            scrollElement.scrollLeft = scrollLeft + scrollDiff;
                            setScrollLeft(scrollElement.scrollLeft);
                            setStartDragX(x);
                        }
                    }}
                    className={styles.buttons}
                >
                    {tabGroupContent.map((content: TabStoryblok, index: number) => {
                        const isSelected = selectedTab._uid === content._uid;
                        const isFirst = index === 0,
                            isLast = index === tabGroupContent.length - 1,
                            isMiddle = !isFirst && !isLast;

                        return (
                            <div
                                ref={el => {
                                    tabRefs.current[content._uid] = el;
                                }}
                                key={content._uid}
                                className={clsx(
                                    styles.button,
                                    isFirst && styles.first,
                                    isLast && styles.last,
                                    isMiddle && styles.middle,
                                )}
                                onClick={() => {
                                    swiper && swiper.slideTo(index, 800, false);
                                    if (selectedTab !== content) {
                                        centerTab(content._uid);
                                    }
                                    setSelectedTab(content);
                                }}
                            >
                                <div className={clsx(styles.selected, [isSelected && styles.active])}>
                                    {content.tabName}
                                </div>
                            </div>
                        );
                    })}
                    {selectorStyle.width !== '0' && <div className={styles.selector} style={selectorStyle} />}
                </div>
            </Container>
            <Container code="fullpage" className={{ wrap: styles.content }}>
                <Swiper
                    modules={[EffectFade, Controller]}
                    allowTouchMove={false}
                    onSwiper={(swiper: any) => setSwiper(swiper)}
                    onSlideChange={({ activeIndex }) => {
                        setSelectedTab(tabGroupContent[activeIndex]);
                    }}
                    cubeEffect={{ shadow: false, slideShadows: false }}
                >
                    {tabGroupContent.map((content: TabStoryblok, index: number) => (
                        <SwiperSlide
                            key={content._uid}
                            className={
                                tabGroupContent[index]._uid === selectedTab._uid
                                    ? styles.slideVisible
                                    : styles.slideHidden
                            }
                        >
                            {content.tabContent?.map((content: SbBlokData) => (
                                <StoryblokComponent blok={content} tabId={content._uid} key={content._uid} />
                            ))}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </Container>
    );
};

export default TabGroup;
